<template>
<div class="flex w-full h-full">
<div class="mt-4 px-32 flex flex-col space-y-4 items-start h-min">
  <p class="font-mono text-amber-400 font-bold text-xl bg-black rounded p-2">Shortcuts</p>
  <p class="font-mono text-amber-400 font-bold text-base text-wrap bg-black rounded p-2">
    <span class="italic">N</span> to play next song
  </p>
  <p class="font-mono text-amber-400 font-bold text-base bg-black rounded p-2">
    <span class="italic">Space</span> to play or pause the current song
  </p>
  <p class="font-mono text-amber-400 font-bold text-base text-wrap bg-black rounded p-2">
    <span class="italic">G</span> to change the GIF
  </p>
</div>

  <div class="fixed bottom-8 w-full">
 <AudioPlayer
    :option="{
        src: current_song_url,
        title: current_song_title,
        autoPlay: true,
        progressBarColor: '#000000',
        indicatorColor: '#ffffff',
        

   }"
   
   
  />

</div>
<div class="mt-4 fixed bottom-0 w-full text-amber-400 bg-black rounded 
font-mono p-2 font-bold text-2xl flex flex-row justify-center items-center">
<a class ="underline"  href="https://www.akulchhillar.com/" target="_blank">
  Built by Akul Chhillar</a>

  
</div>
</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  
}
</script>

<script setup>

import {ref,onMounted,watch} from 'vue'
import AudioPlayer from 'vue3-audio-player'
import 'vue3-audio-player/dist/style.css'
import { useMagicKeys } from '@vueuse/core'
import { isMobile } from 'mobile-device-detect';
import {gifs,songs} from '@/assets.js'
import {useRouter} from 'vue-router'
import { useHead,useSeoMeta } from 'unhead'

const keys = useMagicKeys()
const changeGIF = keys['G']
const changeSong = keys['N']
const play_pause = keys['Space']
let current_song_title = ref('')
let current_song_url = ref('')
let router = useRouter()

useSeoMeta({
  title: '2 AM Cafe | Lofi Music',
  description: 'Lofi Music for the nights',
  ogDescription: 'Lofi Music for the nights',
  ogTitle: '2 AM Cafe | Lofi Music',
  ogImage: 'https://example.com/image.png',
  twitterCard: 'Lofi Music for the nights',
})


watch(changeGIF, (v) => {
  if (v)
  update_bg()
})

watch(changeSong, (v) => {
  if (v)
  get_random_song()
})

watch(play_pause, (v) => {
  if (v){
  let icon = document.getElementsByClassName('audio__player-play-icon')
icon[0].click()}
})



function test(){
  alert(88)
}

onMounted(()=>{
  if(isMobile){router.push('/mobile')}
  get_random_song()
  update_bg()

})

function update_bg(){
  let random_bg = gifs[Math.floor(Math.random()*gifs.length)]
  document.body.style.backgroundImage = `url('${random_bg}')`;
}

function get_random_song(){
  let random_song_url = songs[Math.floor(Math.random()*songs.length)]
  current_song_title.value = random_song_url['title']
  current_song_url.value = random_song_url['url']
}

</script>

<style>
body{
    /* background-image: url('https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExbTBhbjBjbGpqd2o0enBsanIxeXNuNWNpcjB6d280N3FlYWNkZXVsNiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/gfld3S4CsRXRZjqEj3/giphy.gif'); */
    background-size:cover;
    padding:0;
    margin:0;
}


</style>
