gifs = [
'https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExN205eDJhbjJhY255Y3Z3c3R1ZGkyOWlvZjN2MnBhMnkwdXVtcDRiYyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/RMwgs5kZqkRyhF24KK/giphy.gif',
'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExbTBhbjBjbGpqd2o0enBsanIxeXNuNWNpcjB6d280N3FlYWNkZXVsNiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/gfld3S4CsRXRZjqEj3/giphy.gif',
'https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExMmF1bTJoMmNtcXB4Zm5wNWNnMTRoYm90ZW9iNnVrdDZsbmw3MXk4OSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/MU56lYT1Ov07fVTsnM/giphy.gif',
'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExaW9qNDBqa3JuODJlcHE1Yzd0a2dldnkyanZsbTAyd3N6d2Z5cGoxeCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/Mid5u5MisRCK38KnKT/giphy.gif',
'https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExMHltemJ0bnc3c2t5YWN6ZzdhaXoydzBiMThhcmpmd2tlN3gyd3h0aSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/26gs7byN2JOXsBiuY/giphy.gif',
'https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExbHZueGdyenc3cWZxM2Q4aWNscTJwNjRtbG9nYTVxMmMxZmRlcml2aCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3o6fIZSeT3oz47O1IQ/giphy.gif',
'https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExcWgyczFuYnJpMnB1NTA4NWhnZmdjOGhmbTA5anVuOGJlMm56eTkwaiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3og0IBHWUvn7RzzfFu/giphy.gif',
'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExeWhuYjU1ZGNxZnViYmZ2ZjM0Y2p2YmpjdTl1aHBiNHZ0N2MyNHA1YiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/7A80i6IoPMIUDU6250/giphy.gif',
'https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExbXFsNTg5ZnZmNTkxeW04eGJvcjEydTNyNDZkZW03YTkzeTBxc20ybyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/xULW8mb9u2JkoGzvXO/giphy.gif',
'https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExaGpvZHRzMXF0aXZlZ2cxOWh5OWxxbWJ1OTh0bWQ2NThpM2Foc2NrciZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/A5ffIYwJoEpVcMOYiO/giphy.gif',
'https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExdTM4dW0yNTFxZTBrY3QzczRweG03YWR0eWp0aWtvN2UzN2l3eG96aiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/RgZFvGuI4OxLjuSvRF/giphy.gif',
'https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExYzkzNmtxNnUyaTQ3ZTN2emo3d3BiaHVwaW55d2lqNGtrNzdxMjEzYSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/aer096d3vD4rYVsgNn/giphy.gif',
'https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExcDc3OG0ybWxid3hyaWRlcXQ5N2dxNGZkd3ZneGl3MjF5dm5rOGZuayZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/A39hlmeW1On7LOtHO8/giphy.gif',
'https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExMGlvdjBjMDkwbWZlZmVsYmJ4ZHh4Z2kyeGhpZmQ3YWQ2cDBtZ3p3MSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/ttknk7M3d3UBEeZsii/giphy.gif',
'https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExNDhpMHM4ODZ0bHVwNHdraW1qYTB5a3Vrc3ZlZzduM2Y4NzJnNXgxaSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/ssq8oGi0pPO5rMLrEV/giphy.gif',
'https://github.com/akulchhillar/2amcafe/blob/main/Gif/Violet%20Dark%20Blue%20White%20Anime%20Music%20Twitch%20Screen%20(1).gif?raw=true',
'https://github.com/akulchhillar/2amcafe/blob/main/Gif/Violet%20Dark%20Blue%20White%20Anime%20Music%20Twitch%20Screen.gif?raw=true'

]

songs = [
{
    'title': 'Abhi Mujh Mein Kahin',
    'url': 'https://github.com/akulchhillar/2amcafe/raw/main/Audio/%5BSPOTIFY-DOWNLOADER.COM%5D%20Abhi%20Mujh%20Mein%20Kahin%20-%20Lofi%20Flip.mp3'
},
{'title':'Ae Dil Hai Mushkil - Lofi Flip.mp3',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Ae Dil Hai Mushkil - Lofi Flip.mp3'},
{
    'title':'Alvida',
    'url': 'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Alvida - Lofi Flip.mp3'
},
{'title':'Ay Hairathe',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Ay Hairathe - Lofi Flip.mp3'},
{
    'title':'Baarishon Mein',
    'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Baarishon Mein - Lofi Flip.mp3'
},
{'title':'Baatein Ye Kabhi Na',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Baatein Ye Kabhi Na - Lofi Flip.mp3'},
{
    'title':'Bahara',
    'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Bahara - Chill Version.mp3'
},
{'title':'Bairiya',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Bairiya - Chill Flip.mp3'},
{'title':'Bandeya',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Bandeya - Lofi Flip.mp3'},
{'title':'Bheegi Si Bhaagi Si',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Bheegi Si Bhaagi Si - Lofi Flip.mp3'},
{'title':'Bolna',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Bolna - Lofi Flip.mp3'},
{'title':'Bulleya',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Bulleya - Lofi Flip.mp3'},
{
    'title':'Chaand Baaliyan',
    'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Chaand Baaliyan - Lofi Flip.mp3'
},
{
    'title':'Chandaniya',
    'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Chandaniya - Lofi Flip.mp3'
},
{'title':'Channa Mereya',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Channa Mereya - Lofi Flip.mp3'},
{'title':'Daayre',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Daayre - Lofi Flip.mp3'},
{
    'title':'Dil Ibaadat',
    'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Dil Ibaadat - Lofi Flip.mp3'
},
{'title':'Enna Sona',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Enna Sona - Lofi Flip.mp3'},
{'title':'Gehraiyaan',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Gehraiyaan - Lofi Flip.mp3'},
{'title':'Hamari Adhuri Kahani',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Hamari Adhuri Kahani - Lofi Flip.mp3'},
{'title':'Hasi',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Hasi - Lofi Flip.mp3'},
{'title':'Hawayein',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Hawayein - Lofi Flip.mp3'},
{'title':'Heartless',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Heartless - Lofi Flip.mp3'},
{'title':'Hosanna',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Hosanna - Lofi Flip.mp3'},
{'title':'Iktara',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Iktara - Lofi Flip.mp3'},
{'title':'Ishq Bulaava',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Ishq Bulaava - Lofi Flip.mp3'},
{'title':'Ishq Di Baajiyaan',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Ishq Di Baajiyaan - Lofi Flip.mp3'},
{'title':'Jab Se Tere Naina',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Jab Se Tere Naina - Lofi Flip.mp3'},
{'title':'Janam Janam',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Janam Janam - Lofi Flip.mp3'},
{'title':'Jashne Bahara',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Jashne Bahara - Lofi Flip.mp3'},
{'title':'Kabhii Tumhhe',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Kabhii Tumhhe - Female Version - Lofi Flip.mp3'},
{'title':'Kal Ho Naa Ho',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Kal Ho Naa Ho - Lofi Flip.mp3'},
{'title':'Kesariya',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Kesariya - Lofi Flip.mp3'},
{'title':'Kya Baat Ay',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Kya Baat Ay - Lofi Flip.mp3'},
{'title':'Kyon',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Kyon - Lofi Flip.mp3'},
{'title':'Luka Chuppi',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Luka Chuppi - Lofi Flip.mp3'},
{'title':'Main Agar',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Main Agar - Lofi Flip.mp3'},
{'title':'Manchala',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Manchala - Lofi Flip.mp3'},
{'title':'Mat Aazma Re',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Mat Aazma Re.mp3'},
{'title':'Mehrama',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Mehrama - Lofi Flip.mp3'},
{'title':'Mera Pyar Tera Pyar',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Mera Pyar Tera Pyar - Lofi Flip.mp3'},
{'title':'Mere Bina',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Mere Bina - Lofi Flip.mp3'},
{'title':'Muskurane',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Muskurane - Lofi Flip.mp3'},
{'title':'Neele Neele Ambar Par',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Neele Neele Ambar Par - Lofi Flip.mp3'},
{'title':'Noor E Khuda',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Noor E Khuda - Lofi Flip.mp3'},
{'title':'O Meri Jaan',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] O Meri Jaan.mp3'},
{'title':'O Sanam',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] O Sanam - DJ NYK Lofi Remix.mp3'},
{'title':'Pal',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Pal - Lofi Flip.mp3'},
{'title':'Pyaar Ke Pal',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Pyaar Ke Pal - Lofi Flip.mp3'},
{'title':'Raanjhanaa',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Raanjhanaa - Lofi Flip.mp3'},
{'title':'Raataan',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Raataan Chill Mix - Spotify Singles (Tanishk Bagchi, Jubin Nautiyal, Hanita Bhambri).mp3'},
{'title':'Raataan Lambiyan',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Raataan Lambiyan - Lofi Flip.mp3'},
{'title':'Rab Ka Shukrana',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Rab Ka Shukrana - Lofi Flip.mp3'},
{'title':'Ranjha',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Ranjha - Lofi Flip.mp3'},
{'title':'Rasiya',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Rasiya - Lofi Flip.mp3'},
{'title':'Saanson Ko',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Saanson Ko - Lofi Flip.mp3'},
{'title':'Saibo',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Saibo - Lofi Flip.mp3'},
{'title':'Saiyyan',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Saiyyan - Lofi Flip.mp3'},
{'title':'Sajdaa',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Sajdaa - Lofi Flip.mp3'},
{'title':'Samjhawan',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Samjhawan - Lofi Flip.mp3'},
{'title':'Sapna Jahan',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Sapna Jahan - Lofi Flip.mp3'},
{'title':'Sawarne Lage',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Sawarne Lage - Lofi Flip.mp3'},
{'title':'Sham',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Sham - Lofi Flip.mp3'},
{'title':'Shayad',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Shayad - Lofi Flip.mp3'},
{'title':'Shukran Allah',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Shukran Allah - Lofi Flip.mp3'},
{'title':'Sunday',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Sunday.mp3'},
{'title':'Suraj Hua Maddham',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Suraj Hua Maddham - Lofi Flip.mp3'},
{'title':'Tera Deedar Hua',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Tera Deedar Hua - Lofi Flip.mp3'},
{'title':'Tera Zikr',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Tera Zikr - Lofi Flip.mp3'},
{'title':'Tere Bina',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Tere Bina - Lofi Flip.mp3'},
{'title':'Tere Jeya Hor Disda',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Tere Jeya Hor Disda - Lofi Flip.mp3'},
{'title':'Teri Deewani',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Teri Deewani - Lofi Flip.mp3'},
{'title':'Teri Jhuki Nazar',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Teri Jhuki Nazar - Lofi Flip.mp3'},
{'title':'Teri Yaadon Se',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Teri Yaadon Se - Lofi Flip.mp3'},
{'title':'Tu Har Lamha',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Tu Har Lamha - Lofi Flip.mp3'},
{'title':'Tu Hi Haqeeqat',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Tu Hi Haqeeqat - Lofi Flip.mp3'},
{'title':'Tu Hi Mera',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Tu Hi Mera - Lofi Flip.mp3'},
{'title':'Tujhe Sochta Hoon',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Tujhe Sochta Hoon - Lofi Flip.mp3'},
{'title':'Tum Mile',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Tum Mile - Lofi Flip.mp3'},
{'title':'Tum Se',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Tum Se - Lofi Flip.mp3'},
{'title':'Tum Tak',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Tum Tak - Lofi Flip.mp3'},
{'title':'Tumhi Dekho Naa',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Tumhi Dekho Naa - Lofi Flip.mp3'},
{'title':'Yaad',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Yaad - Lofi Flip.mp3'},
{'title':'Yaaron',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Yaaron - Lofi Flip.mp3'},
{'title':'Zara Sa',
'url':'https://github.com/akulchhillar/2amcafe/raw/main/Audio/[SPOTIFY-DOWNLOADER.COM] Zara Sa - Lofi Flip.mp3'}


]

module.exports = {
    gifs,
    songs
}